import { create } from 'zustand'

export const userStore = create(
  (set) => ({
    user: null,
    setUser: (user) =>
      set(() => {
        return { user };
      }),
  })
);

export const loaderStore = create(
  (set) => ({
    loader: true,
    setLoader: (loader) =>
      set(() => {
        return { loader };
      }),
  })
);