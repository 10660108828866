/**
 * Configuración Inicial con Piano
 * @author Carlos Majerhua <c.majerhua@rpalatam.com.pe>
 * @version 1.0
 */

if (typeof window !== 'undefined') {

  // conexion con piano
  window.tp = window.tp || [];
  window.tp.push(['setUsePianoIdUserProvider', true]);
  // insertar el script en el DOM
  (function (src) {
    console.log('src::', src);
    var a = document.createElement('script');
    a.type = 'text/javascript';
    a.async = true;
    a.src = src;
    var b = document.getElementsByTagName('script')[0];
    b.parentNode.insertBefore(a, b);
  })(`https://experience.piano.io/xbuilder/experience/load?aid=BM6tVBSjXE`);
}
