import React, { useState, useEffect } from 'react';
import { fetchLatestCover } from '../../services/api_last_cover';  // Importa tu función
import NewspaperCard from './NewspaperCard'; // Asegúrate de importar tu componente NewspaperCard
const NewspaperGallery = () => {
  const [coverData, setCoverData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  const client = 'lanacion';
  const extension = 'jpg';

  const getLatestCover = async () => {
        setLoading(true);
        const data = await fetchLatestCover(client, extension);  // Llama a la función con parámetros
        if (data) {
          setCoverData(data);
        } else {
          setError('No se pudo obtener la portada más reciente');
        }
        setLoading(false);
  };

  useEffect(() => {
      getLatestCover();
  }, [client, extension]);  // Dependencias de useEffect

   // Reemplazar el mensaje de carga
   if (loading) return (
    <div className="row">
      <div className="col">
        {/* Spinner de carga */}
        <div className="sk-circle sk-primary">
          <link rel="stylesheet" href="../../assets/vendor/libs/spinkit/spinkit.css" />
          <div className="sk-circle-dot"></div>
          <div className="sk-circle-dot"></div>
          <div className="sk-circle-dot"></div>
          <div className="sk-circle-dot"></div>
          <div className="sk-circle-dot"></div>
          <div className="sk-circle-dot"></div>
          <div className="sk-circle-dot"></div>
          <div className="sk-circle-dot"></div>
          <div className="sk-circle-dot"></div>
          <div className="sk-circle-dot"></div>
          <div className="sk-circle-dot"></div>
          <div className="sk-circle-dot"></div>
        </div>
      </div>
    </div>
  );

  if (error) return (
    <div className="alert alert-danger alert-dismissible" role="alert">
      {error} {/* Muestra el mensaje de error */}
      <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
    </div>
  );

  return (
    <section className="section newspaper-gallery my-5">
      <div className="container ">
        <h4 className="text-center mb-4"> 
          <span className="position-relative fw-bold z-1">
            <img 
              src="/assets/img/front-pages/icons/section-title-icon.png" 
              alt="laptop charging" 
              className="section-title-img position-absolute object-fit-contain bottom-0 z-n1"
            />
            Publicaciones
          </span> 
        </h4>
        <div className="text-center mb-4">
          {/* <span className="badge badge-dot bg-primary me-1">Recuerde ingresar a su cuenta para poder hacer u</span> */}
          <span className="badge bg-label-primary">Recuerde ingresar a su cuenta para poder hacer uso de todas las funcionalidades.</span>
        </div>

        <div className="row">
          {coverData && coverData.map((data, index) => (
            <div className="col-lg-4 col-md-6 mb-4" key={index}>
              <NewspaperCard data={data} />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default NewspaperGallery;

  