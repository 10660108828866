
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { userStore, loaderStore } from '../../store';

const Header = () => {

  const { setUser } = userStore();
  const { setLoader } = loaderStore();

  useEffect(() => {
    window.tp = window.tp || [];
    window.tp.push([
      'addHandler',
      'checkoutStateChange',
      function (event) {
        if (
          event.stateName === 'receipt' ||
          event.stateName === 'alreadyHasAccess'
        ) {
          window.tp.offer.close();
          // window.location.reload();
        }
      }
    ]);



    window.tp.push([
      'init',
      async () => {
        await window.tp.experience.init();
        await window.tp.enableGACrossDomainLinking();
        window.tp.pianoId.init({
          loggedIn: function (/*data*/) {
            window.tp.pianoId.loadExtendedUser({
              extendedUserLoaded: function (users) {
                console.log('users v2::', users)
                setUser(users);
                setLoader(false);
              },
              formName: 'MyAccountFields'
            });
          },
          loggedOut: function () {
            console.log('user logged out');
          }
        });
      }
    ]);

    const userData = window.tp?.pianoId?.getUser();
    if (!userData) {
      setLoader(false);
    }
    console.log('userData::', userData);

  }, []);

  const handleLogin = () => {
    window.tp.offer.show({
      offerId: "OF0M69HY9WXW",
      templateId: "OTLHB3NRDHFB",
      directCheckout: true
    });
    //window.tp.pianoId.logout();
  }

  useEffect(() => {
    // Si necesitas ejecutar algún script adicional
    if (window.initCustomComponents) {
      window.initCustomComponents();
    }
  }, []);

  return (
    // <nav className="layout-navbar shadow-none py-0">
    <nav className="layout-navbar navbar-expand-xl align-items-center bg-navbar-theme" >
      <div className="container-xxl">
        <div className="navbar navbar-expand-lg px-3 px-md-8">
          <div className="navbar-brand app-brand demo d-flex py-0 py-lg-2 me-4 me-xl-8">
            <button className="navbar-toggler border-0 px-0 me-4" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <i className="ti ti-menu-2 ti-lg align-middle text-heading fw-medium"></i>
            </button>
            <Link to="/" className="app-brand-link">
              <span className="app-brand-logo demo"> {/* Logo SVG aquí */} </span>
              <span className="app-brand-text demo menu-text txt-bg-primary fw-bold ms-2 ps-1">Kiosko-D</span>
            </Link>
          </div>
          <div className="collapse navbar-collapse landing-nav-menu" id="navbarSupportedContent">
            <button className="navbar-toggler border-0 text-heading position-absolute end-0 top-0 scaleX-n1-rtl" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <i className="ti ti-x ti-lg"></i>
            </button>
            <ul className="navbar-nav me-auto">
              <li className="nav-item">
                <a className="nav-link fw-medium" aria-current="page" href="#home">Subscribete</a>
              </li>
              {/* <li className="nav-item">
                <a className="nav-link fw-medium" aria-current="page" href="#home">Publicaciones</a>
              </li> */}
              {/* Agrega más elementos del menú según sea necesario */}
            </ul>
          </div>
          <ul className="navbar-nav flex-row align-items-center ms-auto">
            <li>
              <button type="button" onClick={handleLogin} className="btn btn-primary"><span className="tf-icons ti ti-login me-md-1"></span><span className="d-none d-md-block">Ingresa/Regístrate</span></button>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Header;
