import React, { useState, useEffect } from 'react';
import Nav from '../components/Nav';
import JournalSlider from '../components/JournalSlider/JournalSlider';
import ImageSlider from '../components/ImageSlider/ImageSlider';
import { ImageProvider} from '../components/ImageContext';
import { useLocation, useNavigate } from 'react-router-dom';
import Overlay from '../components/Overlay/Overlay'; // Asegúrate de importar tu nuevo componente
import { format } from 'date-fns';
import { es } from 'date-fns/locale'; // Para formatear en español

const formatPublishedDate = (dateString) => {
  const date = new Date(dateString + 'T00:00:00'); // Añadir la hora para evitar problemas de zona horaria
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() devuelve el mes de 0 a 11
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
};

const steps = [
  {
    target: '.app-brand', // Selector para el componente JournalSlider
    content: 'Haga clic aquí para volver a la página de inicio.',
  },
  // {
  //   target: '.epaper_name', // Selector para el componente JournalSlider
  //   content: 'Haga clic aquí para volver a la página de inicio'
  // },
  {
    target: '.app-published', // Selector para el componente JournalSlider
    content: 'Calendario para ver ediciones diferentes.',
  },
  {
    target: '.app-user', // Selector para el componente Nav
    content: 'Haga click aquí para iniciar sesión/cerrar sesión.',
  },
  {
    target: '.pagination', // Selector para el componente Nav
    content: 'Haga clic aquí por la tabla de contenidos.',
  },
  
];

const EpaperPage = () => {
  const { state } = useLocation(); // Obtén los datos desde el estado de la navegación
  const navigate = useNavigate();
  const data = state?.data; // Accede a `data` que fue enviado desde NewspaperCard
  const client = data?.client || 'lanacion'; // Valor predeterminado 'lanacion'
  const epaper_name = data?.epaper_name || 'LNA'; // Valor predeterminado 'LNA'
  const defaultPublished  = data?.published || '07-09-2024';
  // ? formatPublishedDate(data.published) 
  // : '07-09-2024'; // Usa la función para formatear la fecha si existe en `data`
  const extension = data?.extension || 'jpg'; // Valor predeterminado 'jpg'
  const epaper = data?.epaper
  const [published, setPublished] = useState(defaultPublished);
  const [runTour, setRunTour] = useState(true); // Controla el estado del tour
  const [showOverlay, setShowOverlay] = useState(true);

  useEffect(() => {
    if (!data) {
      navigate('/'); // Redirige a la raíz o a la ruta que prefieras
    }
  }, [data, navigate]);
  
  const handleDateChange = (newDate) => {
    setPublished(newDate); // Actualiza la fecha seleccionada
  };
  
  const startTour = () => {
    setShowOverlay(false); // Oculta el overlay
    setRunTour(true); // Inicia el tour
  };
  const handleCloseOverlay = () => setShowOverlay(false);


  return (
    <div className="Detail"  style={{backgroundColor: 'black'}} >
        {/* <Nav epaper_data={epaper}  /> */}
        <Nav epaper_data={epaper} published={published} onDateChange={handleDateChange} showDateSelector={true} />
        {/* <JournalSlider /> */}
        <JournalSlider 
          client={client} 
          epaper_name={epaper_name} 
          published={published} 
          extension={extension} 
        />
        {showOverlay && <Overlay onClose={handleCloseOverlay} steps={steps} />}{/* Muestra el overlay si showOverlay es true */}
        
    </div>
  );
};

export default EpaperPage;
