// import logo from './logo.svg';
import './App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import EpaperDetail from './pages/EpaperDetail';
import { GlobalProvider } from './context/GlobalContext';
import './loader.js';
import Loader from './components/loader';
import { loaderStore } from './store';
import ProtectedRoute from './components/ProtectedRoute';
import { userStore } from './store';

function App() {

  const { loader } = loaderStore();
  const { user } = userStore();
  // console.log('PublicURL::', process.env.PUBLIC_URL)
  return (
    <GlobalProvider>
      {loader ? <Loader /> : null}
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/epaper" element={
            <ProtectedRoute isAuthenticated={user}>
              <EpaperDetail />
            </ProtectedRoute>
          } />
        </Routes>
      </Router>
    </GlobalProvider>
  );
}

export default App;
