// api.js

const API_URL = 'https://api.impresa.dev.andesmobile.app'; // Reemplaza esto con la URL de tu API
export const getEpaperDetail = async (client, epaper_name, published, extension) => {
    try {
        const url = `${API_URL}/epapers/pages?client=${client}&epaper_name=${epaper_name}&published=${published}&extension=${extension}`;
        const response = await fetch(url, {
            method: 'GET', 
            headers: {
                'x-api-key': '35db0460aedad58dccf4da2d99ebb4f3'
            },
        });

        if (!response.ok) {
            throw new Error(`Error en la solicitud: ${response.statusText}`);
        }

        return await response.json();
        // return images
    } catch (error) {
            console.error( error);
            return null;
            // return images
    }
    
};
