import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { userStore } from '../store';
import '../css/components/nav.css';
import { format } from 'date-fns';
import { es } from 'date-fns/locale'; // Para formatear en español

const formatPublishedDate = (dateString) => {
  const date = new Date(dateString +'T00:00:00'); // Convierte el string en un objeto Date
  if (isNaN(date.getTime())) {
    return "Fecha no válida"; // Manejo de errores si la fecha es inválida
  }

  return format(date, "d MMM. yyyy", { locale: es });
};


const Nav = ({ epaper_data, page, published, onDateChange, showDateSelector = false }) => {
  const { user, setUser } = userStore();
  const [logout, setLogout] = useState(false);
  const [isInputVisible, setIsInputVisible] = useState(false); // Estado para manejar la visibilidad del input
  const formattedDate = formatPublishedDate(published); // Formatea la fecha aquí

  const handleToggleLogout = () => {
    setLogout(!logout);
  }

  const handleLogout = () => {
    window.tp.pianoId.logout();
    setUser(null);
    window.location.href = '/'
  }

  useEffect(() => {
    if (window.initCustomComponents) {
      window.initCustomComponents();
    }
  }, []);


  const handleDateChange = (e) => {
    const newDate = e.target.value;
    onDateChange(newDate); // Llamar la función para actualizar la fecha en el componente padre
  };

  const handleInputFocus = () => {
    setIsInputVisible(true); // Muestra el input al hacer clic
  };

  const handleInputBlur = () => {
    setIsInputVisible(false); // Oculta el input al perder el foco
  };
  
  return (
    <nav className="layout-navbar navbar-expand-xl align-items-center bg-navbar-theme" >
      <div className="container-xxl">
        <div className="navbar navbar-expand-lg px-3 px-md-8">
          <div className="navbar-brand app-brand demo d-flex py-0 py-lg-2 me-4 me-xl-8">
            <button className="navbar-toggler border-0 px-0 me-4" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <i className="ti ti-menu-2 ti-lg align-middle text-heading fw-medium"></i>
            </button>
            <Link to="/" className="app-brand-link">
              <span className="app-brand-logo demo"> {/* Logo SVG aquí */} </span>
              <span className="app-brand-text demo menu-text txt-bg-primary fw-bold ms-2 ps-1">Kiosko-D</span>
            </Link>
          </div>

          {user && page === 'home' ?
            null : <ul className="navbar-nav flex-row align-items-center ms-auto app-epaper-name">
              <li className="d-flex align-items-center ">
                <div className="me-3 epaper_name ">
                  <h3 className="fw-bold text-primary ">{epaper_data.name} ({epaper_data.epaper_name})</h3>
                </div>
                <div className="me-3 app-published">
                  {!isInputVisible ? (
                    <button 
                    className="btn btn-light" 
                    onClick={handleInputFocus} // Muestra el input al hacer clic
                    style={{ cursor: 'pointer' }}
                  >
                    {formattedDate} {/* Muestra la fecha formateada */}
                  </button>
                    // <h5 className="fw-bold text-secondary" style={{ cursor: 'pointer' }} onClick={handleInputFocus}>
                    //   {formattedDate}
                    // </h5>
                  ) : (
                    <input
                      type="date"
                      className="form-control"
                      aria-label="Fecha"
                      value={published} // Establecer el valor actual
                      onChange={handleDateChange}
                      onBlur={handleInputBlur} // Ocultar el input al perder foco
                      onFocus={(e) => e.target.select()} // Opcional: Seleccionar el valor al enfocarse
                    />
                  )}
                </div>
                
              </li>
            </ul>
          }
          <ul className="navbar-nav flex-row align-items-center ms-auto app-user ">
            <li className="nav-item navbar-dropdown dropdown-user dropdown">
              <div className="container-profile" onClick={handleToggleLogout}>
                <div className="avatar avatar-online">
                  <img src="../../assets/img/avatars/1.png" alt className="rounded-circle" />
                </div>
                <h6 className="mb-0">{user?.first_name + ' ' + user?.last_name}</h6>
              </div>
              {logout ? <ul className="dropdown-menu-end">
                <div className="d-grid px-2 pt-2 pb-1">
                  <button type="button" className="btn btn-sm btn-danger d-flex" onClick={handleLogout}>
                    <small className="align-middle">Salir</small>
                    <i className="ti ti-logout ms-2 ti-14px"></i>
                  </button>
                </div>
              </ul> : null}
            </li>
          </ul>
          <div className="navbar-search-wrapper search-input-wrapper container-xxl d-none">
            <input type="text" className="form-control search-input border-0" placeholder="Search..." aria-label="Search..." />
            <i className="ti ti-x search-toggler cursor-pointer"></i>
          </div>
        </div>
      </div>
    </nav>

    // <nav className="layout-navbar navbar navbar-expand-xl align-items-center bg-navbar-theme" id="layout-navbar">
    //   <div className="container">
    //       <div className="navbar-brand app-brand demo d-flex py-0 py-lg-2 me-4 me-xl-8">
    //         <button className="navbar-toggler border-0 px-0 me-4" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    //           <i className="ti ti-menu-2 ti-lg align-middle text-heading fw-medium"></i>
    //         </button>
    //         <Link to="/" className="app-brand-link">
    //           <span className="app-brand-logo demo"> {/* Logo SVG aquí */} </span>
    //           <span className="app-brand-text demo menu-text txt-bg-primary fw-bold ms-2 ps-1">Kiosko-D</span>
    //         </Link>
    //       </div>
    //       <div className="collapse navbar-collapse landing-nav-menu" id="navbarSupportedContent">
    //         <button className="navbar-toggler border-0 text-heading position-absolute end-0 top-0 scaleX-n1-rtl" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    //           <i className="ti ti-x ti-lg"></i>
    //         </button>
    //         <ul className="navbar-nav me-auto">
    //           <li className="nav-item">
    //             <a className="nav-link fw-medium" aria-current="page" href="#home">Subscribete</a>
    //           </li>
    //           {/* <li className="nav-item">
    //             <a className="nav-link fw-medium" aria-current="page" href="#home">Publicaciones</a>
    //           </li> */}
    //           {/* Agrega más elementos del menú según sea necesario */}
    //         </ul>
    //       </div>

    //     <div className="navbar-nav-right d-flex align-items-center" id="navbar-collapse">
    //       <ul className="navbar-nav flex-row align-items-center ms-auto">
    //         <Search />
    //         <UserDropdown />
    //       </ul>
    //     </div>
    //     <div className="navbar-search-wrapper search-input-wrapper container-xxl d-none">
    //       <input type="text" className="form-control search-input border-0" placeholder="Search..." aria-label="Search..." />
    //       <i className="ti ti-x search-toggler cursor-pointer"></i>
    //     </div>
    //   </div>
    // </nav>
  );
};

export default Nav;
