import React, { createContext, useState } from 'react';

// Crea el contexto
export const GlobalContext = createContext();

// Proveedor del contexto
export const GlobalProvider = ({ children }) => {
  // Definir las variables globales aquí
  const [client, setClient] = useState('lanacion');
  const [extension, setExtension] = useState('jpg');

  return (
    <GlobalContext.Provider value={{ client, setClient, extension, setExtension }}>
      {children} {/* Todo lo que esté dentro tendrá acceso a estas variables */}
    </GlobalContext.Provider>
  );
};
