import React from 'react';
import { Link } from 'react-router-dom';
// import heroBg from '../../assets/img/front-pages/backgrounds/hero-bg.png';
// import joinCommunityArrow from '%PUBLIC_URL%/assets/img/front-pages/icons/Join-community-arrow.png';
// import heroDashboardLight from '%PUBLIC_URL%/assets/img/front-pages/landing-page/hero-dashboard-light.png';
// import heroElementsLight from '%PUBLIC_URL%/assets/img/front-pages/landing-page/hero-elements-light.png';
import './style.css'; 

const Hero = () => {
  return (
    <section id="hero-animation" className="hero-small-section py-4">
      <div className="container text-center">
        <h1 className="text-primary hero-title display-6 fw-extrabold">
        Noticias de Costa Rica y el mundo
        </h1>
        <p className="hero-subtitle lead mt-3">
        Política, economía, deportes, ciencia y cultura, entre otros temas.
        </p>
      </div>
    </section>
  );
};

export default Hero;
