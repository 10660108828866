import React, { useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore from 'swiper';
import { Zoom, Navigation, Pagination, Scrollbar  } from 'swiper/modules';
import { getEpaperDetail } from '../../services/api_get_epaper_detail'; 
import { getTableIndex } from '../../services/api_get_get_table_section'; 
import LoadingSpinner from '../Common/LoadingSpinner/LoadingSpinner';
import ErrorAlert from '../Common/ErrorAlert/ErrorAlert';
import AlertMessage from '../Common/AlertMessage/AlertMessage';

// import ZoomImg from 'react-medium-image-zoom'
// import 'react-medium-image-zoom/dist/styles.css'
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './ImageSlider.css';  // Importa los estilos

SwiperCore.use([Navigation, Pagination, Zoom, Scrollbar]);

const fetchTableIndex = async (client, epaper_name, published, setTableIndix) => {
    try {
        const data = await getTableIndex(client, epaper_name, published);
        console.log("data fetchTableIndex :", data)
        if (data) {
            setTableIndix(data); 
        } 
    } catch (err) {
        console.error("Error al cargar datos adicionales:", err);
    }
};

const ImageSlider = ({ client, epaper_name, published, extension }) => {
    const swiperRef = useRef(null);
    const [images, setImages] = useState([]); // Estado para almacenar las imágenes
    const [tableIndix, setTableIndix] = useState([]); // Estado para almacenar las imágenes
    const [currentPage, setCurrentPage] = useState(0); // Estado para la página actual
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isZoomed, setIsZoomed] = useState(false); // Estado para controlar el zoom

    const fetchData = async () => {
        // console.log( client, epaper_name, published, extension)
        const data = await getEpaperDetail(client, epaper_name, published, extension);
        console.log("data")
        console.log(data)
        if (data) {
            setImages(data); 
            setCurrentPage(data[0]?.page_num || 0);
        } else {
            setError('No se pudo obtener la portada más reciente');
        }
        setLoading(false);
    };

    

    useEffect(() => {
      fetchData(); // Llama a la función para obtener datos
    }, [client, epaper_name, published, extension]); // Dependencias

    useEffect(() => {
     fetchTableIndex(client, epaper_name, published, setTableIndix)
     console.log("tableIndex :", tableIndix)
    }, [client, epaper_name, published]); // Dependencias

    const handlePageChange = (index) => {
        const currentIndex = swiperRef.current?.swiper?.activeIndex;
        if (currentIndex !== index) {
            if (swiperRef.current) {
                swiperRef.current.swiper.slideTo(index); // Cambiamos a la diapositiva correspondiente por índice
                setCurrentPage(images[index].page_num); // Actualiza el estado con el número de página correspondiente
            }
        }
    };

    if (loading) return <LoadingSpinner />;    
    if (error) return <ErrorAlert message={error} onClose={() => setError(null)} />;
    if (!images.length) return <AlertMessage message="No hay imágenes disponibles." type="primary" />;

    return (
        <>  {/* Swiper principal con Zoom */}
            <div 
                className={`image-slider-container container-fluid ${isZoomed ? 'zoom-active' : ''}`}  >
                <Swiper
                        ref={swiperRef} 
                        zoom = {{ maxRatio: 3, minRatio: 1 }}
                        navigation={true} // navigation={!isZoomed}
                        scrollbar={true}
                        slidesPerView={"auto"}
                        spaceBetween={2}
                        freeMode={true}
                        mousewheel={true}
                        watchSlidesProgress={true}
                        slidesPerGroupSkip={1}
                        breakpoints={{
                            640: {slidesPerView: 1, spaceBetween: 2, },
                            768: {slidesPerView: 2, spaceBetween: 2, },
                            1024: {slidesPerView: 3, spaceBetween: 2, },
                        }}
                        className={"swiper-thumbs"}
                        >
                        
                        {images.map((image, index) => (
                            <SwiperSlide  key={index}    >
                                <div  className="thumb-slide"  >
                                <div className="text-start text-white small">{image.page_num}</div>
                                {
                                    image.extension === "pdf" ? (
                                        <iframe
                                            src={`https://drive.google.com/viewerng/viewer?embedded=true&url=${image.uri}`}
                                            title={`PDF ${image.page_num}`}
                                            className="w-100 h-100 border-0 slider-iframe"
                                            allowFullScreen
                                        />
                                    ) : (
                                            <div className="swiper-zoom-container">
                                                <img
                                                    src={image.uri}
                                                    alt={`Image ${String(image.page_num)}`}
                                                    className= "w-100 h-100 mx-auto"
                                                    data-swiper-zoom="3"
                                                    onLoad={() => swiperRef.current.swiper.update()}
                                                />
                                            </div>
                                        
                                    )
                                }
                                

                                </div>
                            </SwiperSlide>
                        ))}
                </Swiper>
            </div>
            {/* Swiper principal con Zoom */}

            {/* Botones a la izquierda */}
            <div className="d-flex justify-content-between align-items-center custom-background ">
                    {/* <div className="me-5 text-center p-1">
                        <label className="switch switch-square switch-lg">
                            <input type="checkbox" className="switch-input" />
                            <span className="switch-toggle-slider">
                            <span className="switch-on"><i className="ti ti-check"></i></span>
                            <span className="switch-off"><i className="ti ti-x"></i></span>
                            </span>
                        </label>
                    </div> */}
                    <Swiper
                                className="swiper-thumbs px-5"
                                spaceBetween={1}
                                slidesPerView={2}
                                freeMode={true}
                                watchSlidesProgress={true}
                                navigation // Activar navegación
                                breakpoints={{
                                    576: { slidesPerView: 2,},
                                    768: {slidesPerView: 3, },
                                    992: {slidesPerView: 5, },
                                    1200: {slidesPerView: 7,},
                                }}
                            >
                                {

                                    tableIndix.length > 0  ? (
                                        Object.entries(tableIndix[0].indices).map(([key, pages], index) => {
                                            const firstPage = pages[0]-1; 
                                            return ( // Necesario para devolver el JSX
                                                <SwiperSlide key={`${key}-${index}`}>
                                                    <div className="thumb-slide" onClick={() => handlePageChange(firstPage)}>
                                                        <div className="page-number">
                                                            {/* {key}: {firstPage} */}
                                                            {key}
                                                        </div>
                                                    </div>
                                                </SwiperSlide>
                                            );
                                        })
                                        
                                    ) : (
                                        images.map((image, index) => (
                                            <SwiperSlide key={index}>
                                                <div className="thumb-slide" onClick={() => handlePageChange(index)}>
                                                    <div className="page-number">
                                                        Pagina {image.page_num}
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                        ))
                                    )
                                
                                }
                    </Swiper>       
            </div>
        </>
    );
};

export default ImageSlider;