import React, { useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore from 'swiper';
import { Thumbs, Navigation, Pagination, Zoom } from 'swiper/modules';
import { getEpaperDetail } from '../../services/api_get_epaper_detail';  
import 'swiper/css'; 
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/zoom';
import 'swiper/css/thumbs';


SwiperCore.use([Navigation, Pagination, Zoom, Thumbs]);

const ImageSlider = ({ client, epaper_name, published, extension }) => {
  const [images, setImages] = useState([]);
  const [currentPage, setCurrentPage] = useState(0); // Estado para la página actual
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [thumbsSwiper, setThumbsSwiper] = useState(null); // Estado para el swiper de thumbs
  const swiperRef = useRef(null);

  const fetchData = async () => {
    try {
      const data = await getEpaperDetail(client, epaper_name, published, extension);
      setImages(data || []);
    } catch (err) {
      setError('No se pudo obtener la portada más reciente');
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [client, epaper_name, published, extension]);

  useEffect(() => {
        if (swiperRef.current) {
            swiperRef.current.swiper.update();
        }
  }, [images]);


  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!images || images.length === 0) return <div>No hay imágenes disponibles.</div>;

  return (
    <div className="row my-1">
      <div className="col-12 mb-6">
        <Swiper
          className="swiper-wrapper"
          zoom 
          ref={swiperRef}
          spaceBetween={20}
          navigation
          thumbs={{ swiper: thumbsSwiper }} // Conectamos con los thumbs
        //   pagination={{ clickable: true }}
          breakpoints={{
            640: { slidesPerView: 1 },
            768: { slidesPerView: 2 },
            1024: { slidesPerView: 3 },
          }}
        >
          {images.map((image, index) => (
            <SwiperSlide key={index} className="swiper-slide">
              {image.extension === "pdf" ? (
                <iframe
                  src={image.uri}
                  title={`PDF ${image.page_num}`}
                  style={{ width: '100%', height: '100%', border: 'none' }}
                  allowFullScreen
                />
              ) : (
                <img
                  src={image.uri}
                  alt={`Image ${image.page_num}`}
                  className="image-slide"
                  onLoad={() => swiperRef.current.swiper.update()}
                />
              )}
            </SwiperSlide>
          ))}
        </Swiper>

        {/* Swiper para los thumbs */}
        <Swiper
          className="mySwiper"
          onSwiper={setThumbsSwiper}
          spaceBetween={10}
          slidesPerView={6}
          freeMode={true}
          watchSlidesProgress={true} // Necesario para que los thumbs sigan el progreso
        >
          {images.map((image, index) => (
            <SwiperSlide key={index}>
                Pag {image.page_num}
              {/* <img
                src={image.uri}
                alt={`Thumb ${image.page_num}`}
                style={{ width: '100%', height: '100%', objectFit: 'contain' }}
              /> */}
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default ImageSlider;
