import React from 'react';
import './LoadingSpinner.css'; // Asegúrate de tener los estilos

const LoadingSpinner = () => (
    <div className="loading-container">
        <div className="spinner">
            <div className="sk-circle sk-primary">
                <div className="sk-circle-dot"></div>
                <div className="sk-circle-dot"></div>
                <div className="sk-circle-dot"></div>
                <div className="sk-circle-dot"></div>
                <div className="sk-circle-dot"></div>
                <div className="sk-circle-dot"></div>
                <div className="sk-circle-dot"></div>
                <div className="sk-circle-dot"></div>
                <div className="sk-circle-dot"></div>
                <div className="sk-circle-dot"></div>
                <div className="sk-circle-dot"></div>
                <div className="sk-circle-dot"></div>
            </div>
        </div>
    </div>
);

export default LoadingSpinner;
