import React from 'react';
import { useNavigate } from 'react-router-dom';
import { userStore } from '../../store';

const NewspaperCard = ({ data }) => {
  const { published, uri, epaper_name, page_num, client, extension, epaper } = data;

  const { user } = userStore();
  const navigate = useNavigate();

  const handleNavigation = () => {
      navigate('/epaper', {
        state: { data}
      });
  };

  return (
    <div className="col-lg">
      {/* <div className="card h-100">
          <div className="card-body ">
            <h5 className="card-title text-capitalize text-center">{epaperName}</h5>
            <div className="container ">
              <img src={uri} alt={`Page ${pageNum} of ${client}`} height="300" className='mx-auto my-6 rounded'/>
              <img className="img-fluid d-flex mx-auto my-6 rounded" src={uri} alt="Card image cap" height="400"/>
            </div>
            
            <div className="text-center mt-3">
              <button onClick={handleNavigation} className="btn  btn-primary">
                Ingresa aqui
              </button>
            </div>
          </div>
        </div> */}
      <div className="card rounded shadow" >
        <div className="card-body px-1">
          <div className="container mx-1 my-1 text-center">
            {extension === 'pdf' ? (
              // Si es un PDF, muestra un enlace para abrirlo o un iframe
              <iframe
                src={`https://drive.google.com/viewerng/viewer?embedded=true&url=${uri}`}
                title={`Page ${page_num} of ${epaper_name}`}
                width="100%"
                height="350"
                style={{ border: "none" }}>
              </iframe>
            ) : (
              // Si no es PDF, renderiza la imagen como antes
              <img src={uri} alt={`Page ${page_num} of ${epaper_name}`} height="350" />
            )
            }
            {/* <img src={uri} alt={`Page ${pageNum} of ${client}`} height="350"/> */}
          </div>
          <h4 className="card-title text-center text-capitalize mb-1">{epaper.name}</h4>
          <div className="text-center mb-1">
            {user ? <button onClick={handleNavigation} className="btn  btn-primary">
              Ingresa aqui
            </button> : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewspaperCard;

