
import React, { useEffect, useState } from 'react';
import Nav from '../components/Nav';
import Header from '../components/Header/Header';
import NewspaperGallery from '../components/CoverGallery/NewspaperGallery';
import Hero from '../components/Hero/hero';
import { userStore } from '../store';

const Home = () => {
  const { user } = userStore();

  const epaper_name = 'LNA'; // Valor predeterminado 'LNA'
  useEffect(() => {
    // console.log('user::', user);
  }, [user]);
  return (
    <div className="Home">
      {user ? <Nav epaper_data={{}} page={"home"} /> : <Header />}
      <Hero />
      <NewspaperGallery />
    </div>
  );
};

export default Home;