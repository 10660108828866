
const API_URL = 'https://api.impresa.dev.andesmobile.app'; 
const  Index = [
    {
        "indices": {
            "PORTADA": [
                "1"
            ],
            "DE SETIEMBRE": [
                "2"
            ],
            "EL PAÍS": [
                "3",
                "4",
                "5",
                "6",
                "7",
                "10",
                "11"
            ],
            "SUCESOS&JUDICIALES": [
                "12",
                "13"
            ],
            "VIVA": [
                "15",
                "16",
                "17"
            ],
            "ECONOMÍA": [
                "20",
                "21",
                "22"
            ],
            "EL MUNDO": [
                "23",
                "24"
            ],
            "JEFES DE INFORMACIÓN": [
                "25"
            ],
            "PURO DEPORTE": [
                "30",
                "31"
            ],
            "PURO": [
                "32"
            ],
        },
        "epaper_name": "LNA",
        "published": "2024-09-20",
        "total_pages": "0",
        "client": "lanacion",
        "timestamp": "2024-10-29T14:56:47.065166"
    }
]

export const getTableIndex = async (client, epaper_name, published) => {
    try {
        const url = `${API_URL}/epapers/table-section?client=${client}&epaper_name=${epaper_name}&published=${published}`;
        const response = await fetch(url, {
            method: 'GET', 
            headers: {
                'x-api-key': '35db0460aedad58dccf4da2d99ebb4f3'
            },
        });

        if (!response.ok) {
            throw new Error(`Error en la solicitud: ${response.statusText}`);
        }

        const data = await response.json();
        return data; // Asegúrate de que esto sea la estructura correcta que deseas
        // return Index;
    } catch (error) {
            console.error( error);
            return null;
            // return images
    }
    
};