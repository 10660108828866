
const API_URL = 'https://api.impresa.dev.andesmobile.app'; 

export const fetchLatestCover = async (client, extension) => {
  try {
      const url = `${API_URL}/epapers/latest-cover?client=${client}&extension=${extension}`;
      const requestOptions = {
        method: 'GET'
      };

      const response = await fetch(url, requestOptions);
      if (!response.ok) {
          throw new Error(`Error ${response.status}: ${response.statusText}`);
      }

      const data = await response.json();
      return data
  } catch (error) {
      console.error('Error : ', error);
      return null;
  }
};
