import React, { useEffect, useState } from 'react';

const Overlay = ({ onClose, steps }) => {
  const [stepPositions, setStepPositions] = useState([]);

  useEffect(() => {
    const positions = steps.map((step) => {
      const element = document.querySelector(step.target);
      if (element) {
        const rect = element.getBoundingClientRect();
        let top = rect.top + window.scrollY;
        let left = rect.left + window.scrollX;

        const offset = 50;
        const offset_left = 20;
        let top_ = top + rect.height;
        let left_ = left + rect.width;

        if (top_ > window.innerHeight) {
          top = window.innerHeight - rect.height - offset;
        }
        if (left_ > window.innerWidth) {
          left = window.innerWidth - rect.width - offset_left;
        }

        return {
          ...step,
          top,
          left,
          width: rect.width,
          height: rect.height,
        };
      }
      return null;
    }).filter(Boolean);

    setStepPositions(positions);
  }, [steps]);

  return (
    <>
      <div style={overlayStyles} className='container-fluid text-center '> 
        <div style={contentStyle}>
          <p>Cambia las páginas usando el mouse para pasar las páginas hacia ambos lados</p>
        </div>
        <button onClick={onClose} style={buttonStyles}>Cerrar Guía</button>
        
        {stepPositions.map((step, index) => (
  
            <div
              key={index}
              style={{
                ...stepStyles,
                top: `${step.top + 50 }px`,
                left: `${step.left}px`,
                position: 'absolute',
              }}
            >
              <div style={{ position: 'relative' }}>
                {/* Contenido del paso */}
                <div style={contentStyle}>
                  <p>{step.content}</p>
                </div>
              </div>
            </div>
          ))}

      </div>
    </>
  );
};

const contentStyle = {
  textAlign: 'center',
  backgroundColor: 'rgba(255, 69, 0, 0.9)',
  // border: '1px solid #FF4500',
  color: '#fff',
  padding: '1vh',
  borderRadius: '5px',
  width: '90%', // Ajuste ancho responsivo
  maxWidth: '300px', // Límite máximo
  margin: 'auto', // Centrado
};

const lineStyle = {
  position: 'absolute',
  top: 0,
  // left: '50%', // Centrado horizontalmente
  width: '2px',
  height: '10vh',
  // backgroundColor: 'transparent', // Hacer la línea transparente
  backgroundColor: '#FF4500', // Cambiado a un color visible
  // transform: 'translateX(-50%)',
};

// Estilos para el overlay
// const overlayStyles = {
//   position: 'fixed',
//   top: 0,
//   left: 0,
//   right: 0,
//   bottom: 0,
//   backgroundColor: 'rgba(0, 0, 0, 0.6)',
//   zIndex: 9999,
//   display: 'flex',
//   justifyContent: 'center',
//   alignItems: 'center',
//   flexDirection: 'row', // Alinear elementos en filas
//   padding: '2vw', // Espaciado interno en pantallas pequeñas
// };
const overlayStyles = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: '45px',
  backgroundColor: 'rgba(0, 0, 0, 0.8)',
  color: '#fff',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '20px',
  zIndex: 9999,
  overflowY: 'auto',
};


// Estilos para el botón de cerrar
const buttonStyles = {
  // position: 'fixed',
  // top: '55%',
  // left: '50%',
  // transform: 'translateX(-50%)',
  padding: '1.2vw 3vw',
  backgroundColor: '#FF4500',
  boxShadow: '0 0 10px 5px rgba(255, 70, 0, 0.7)',
  color: '#fff',
  border: 'none',
  cursor: 'pointer',
  // fontSize: '3vw',
  fontSize: '1.5rem',
  zIndex: 10001,
  borderRadius: '5px',
};

// Estilos para los pasos
const stepStyles = {
  marginTop: '7vh',
  // border: '1px solid #FF4500',
  width: '80vw',  // Se adapta al ancho de la pantalla
  maxWidth: '180px', // Limita el tamaño máximo
  position: 'absolute',
  zIndex: 10000,
};

export default Overlay;


// import React, { useEffect, useState } from 'react';

// const Overlay = ({ onClose, steps }) => {
//   const [stepPositions, setStepPositions] = useState([]);

//   useEffect(() => {
//     // Obtenemos las posiciones de cada elemento objetivo (target)
//     const positions = steps.map((step) => {
//       const element = document.querySelector(step.target);
//       if (element) {
//         const rect = element.getBoundingClientRect();
//         let top = rect.top + window.scrollY;
//         let left = rect.left + window.scrollX;

//         const offset = 50;
//         const offset_left = 20;
//         let top_ = top + rect.height + offset;
//         let left_ = left + rect.width + offset_left;

//         if (top_ > window.innerHeight) {
//           top = window.innerHeight - rect.height - offset;
//         }
//         if (left_ > window.innerWidth) {
//           left = window.innerWidth - rect.width - offset;
//         }

//         return {
//           ...step,
//           top, // Usar el valor ajustado
//           left, // Usar el valor ajustado
//           width: rect.width,
//           height: rect.height,
//         };
//       }
//       return null;
//     }).filter(Boolean); // Filtramos los que no encontraron el target

//     setStepPositions(positions);
//   }, [steps]);

//   return (
//     <>
//       {/* El overlay cubre toda la página */}
//       <div style={overlayStyles}>
//         <button onClick={onClose} style={buttonStyles}>Cerrar Guía</button>
        
//         {/* Iteramos sobre los pasos y mostramos las descripciones */}
//         {stepPositions.map((step, index) => (
//           <div
//               key={index}
//               style={{
//                 ...stepStyles,
//                 top: `${step.top}px`,    // Ajustar la posición superior
//                 left: `${step.left }px`,  // Ajustar la posición izquierda
//                 position: 'relative',    // Necesario para la línea y contenido
//                 paddingTop: '20px',      // Espacio para la línea
//               }}
//             >
//               {/* Línea vertical centrada en el target */}
//               <div
//                 style={{
//                   content: '""',
//                   position: 'absolute',
//                   top: `${step.top}px`,            // Mover la línea arriba del target (ajusta según el tamaño)
//                   left: `${step.left + 50}px`,             // Centrar la línea respecto al target
//                   width: '2px',            // Grosor de la línea
//                   height: `${step.height + 30}px`, // Línea desde el top del target hasta justo antes del contenido
//                   backgroundColor: '#FF4500', // Color de la línea
//                   transform: 'translateX(-50%)', // Centrar la línea horizontalmente
//                 }}
//               />
              
//               <div
//                 style={{
//                   textAlign: 'center',
//                   backgroundColor: 'rgba(255, 69, 0, 0.9)',
//                   border: '1px solid #FF4500',
//                   color: '#fff',
//                   padding: '5px',
//                   borderRadius: '5px',
//                   marginTop: '60px',  // Espacio suficiente para la línea
//                   textAlign: 'center' // Centramos el contenido
//                 }}
//               >
//                 <p>{step.content}</p>
//               </div>
              
//           </div>
        
        
        
//           // <div
//           // key={index}
//           // style={{
//           //   ...stepStyles,
//           //   top: `${step.top }px`,   // Usar el valor ajustado
//           //   left: `${step.left }px`, // Usar el valor ajustado
//           // }}
//           // >
//           //     {/* <h3 style={stepTitleStyles}>Paso {index + 1}</h3> */}
//           //     <p>{step.content}</p>
//           // </div>
//         ))}
//       </div>
//     </>
//   );
// };

// // Estilos para el overlay
// const overlayStyles = {
//   position: 'fixed',
//   top: 0,
//   left: 0,
//   right: 0,
//   bottom: 0,
//   backgroundColor: 'rgba(0, 0, 0, 0.7)', // Fondo oscuro para el overlay
//   zIndex: 9999, // Asegura que esté sobre todo
// };

// // Estilos para el botón de cerrar
// const buttonStyles = {
//   position: 'absolute',
//   top: '50%',  // Centra verticalmente
//   left: '50%', // Centra horizontalmente
//   transform: 'translate(-50%, -50%)', // Ajusta la posición para que el centro del botón esté en el medio
//   padding: '10px 20px',
//   backgroundColor: '#FF4500',
//   color: '#fff',
//   border: 'none',
//   cursor: 'pointer',
//   zIndex: 10001, // Botón encima del overlay
// };

// // const buttonStyles = {
// //   position: 'absolute',
// //   top: '20px',
// //   right: '20px',
// //   padding: '10px 20px',
// //   backgroundColor: '#FF4500',
// //   color: '#fff',
// //   border: 'none',
// //   cursor: 'pointer',
// //   zIndex: 10001, // Botón encima del overlay
// // };

// // Estilos para los pasos
// const stepStyles = {
//   width: '15%',
//   position: 'relative', 
//   // position: 'absolute',
//   // backgroundColor: 'rgba(255, 69, 0, 0.9)',
//   // textAlign: 'center',
//   // border: '1px solid #FF4500',
//   // color: '#fff',
//   // padding: '5px',
//   // borderRadius: '5px',
//   zIndex: 10000,
  
// //   marginBottom: '100px',
// //   textAlign: 'center',
// //   border: '1px solid #FF4500',
// };

// Estilos para los títulos de los pasos
// const stepTitleStyles = {
//   color: '#FFF',
//   fontSize: '18px',
//   marginBottom: '10px',
// };

// export default Overlay;

//************************************************************************************************************ */
// const Overlay = ({ onClose, steps }) => {
//   return (
//     <div style={overlayStyles}>
//       <h1 style={titleStyles}>Guía de la aplicación</h1>
//       {steps.map((step, index) => (
//         <div key={index} style={stepContainerStyles}>
//           <h3 style={stepTitleStyles}>Paso {index + 1}</h3>
//           <p style={stepTextStyles}>{step.content}</p>
//         </div>
//       ))}
//       <button onClick={onClose} style={buttonStyles}>Cerrar Guía</button>
//     </div>
//   );
// };
// import React, { useEffect } from 'react';

// const Overlay = ({ onClose, steps }) => {
//   // Función para mostrar el contenido debajo de los elementos del target
//   useEffect(() => {
//     steps.forEach((step, index) => {
//       // Seleccionamos el elemento usando el selector target
//       const element = document.querySelector(step.target);
      
//       if (element) {
//         // Creamos un div que contendrá el paso
//         const stepContainer = document.createElement('div');
//         stepContainer.style = stepContainerStyles;  // Aplicamos los estilos del contenedor

//         // Creamos un h3 para el título del paso
//         const stepTitle = document.createElement('h3');
//         stepTitle.textContent = `Paso ${index + 1}`;
//         stepTitle.style = stepTitleStyles;

//         // Creamos un p para el contenido del paso
//         const stepText = document.createElement('p');
//         stepText.textContent = step.content;
//         stepText.style = stepTextStyles;

//         // Añadimos el título y el texto al contenedor
//         stepContainer.appendChild(stepTitle);
//         stepContainer.appendChild(stepText);

//         // Insertamos el contenedor justo después del elemento seleccionado
//         element.insertAdjacentElement('afterend', stepContainer);
//       }
//     });

//     // Cleanup: Removemos los elementos cuando se cierra el overlay
//     return () => {
//       steps.forEach((step) => {
//         const element = document.querySelector(step.target);
//         if (element && element.nextElementSibling?.tagName === 'DIV') {
//           element.nextElementSibling.remove();
//         }
//       });
//     };
//   }, [steps]);

//   return (
//     <div style={overlayStyles}>
//       <h1 style={titleStyles}>Guía de la aplicación</h1>
//       <button onClick={onClose} style={buttonStyles}>Cerrar Guía</button>
//     </div>
//   );
// };

// // Estilos para el contenedor de cada paso
// const stepContainerStyles = {
//   color: '#FF4500',
//   position: 'absolute',
//   // top: `${element.getBoundingClientRect().bottom + 40}px`, // Mover 40px hacia abajo
//   // left: `${element.getBoundingClientRect().left}px`,
//   width: '80%',
//   marginBottom: '100px',
//   textAlign: 'center',
//   border: '1px solid #FF4500',
//   padding: '10px',
//   borderRadius: '5px',
//   width: '80%',
//   color: '#fff',
// };

// // Estilos para el título de cada paso
// const stepTitleStyles = {
//   color: '#FF4500',
//   fontSize: '18px',
//   marginBottom: '10px',
// };

// // Estilos para el texto de cada paso
// const stepTextStyles = {
//   color: '#fff',
// };

// // Resto de los estilos del overlay

// const titleStyles = {
//   color: '#FF4500',
//   marginBottom: '20px',
// };

// const buttonStyles = {
//   marginTop: '20px',
//   padding: '10px 20px',
//   backgroundColor: '#FF4500',
//   color: '#fff',
//   border: 'none',
//   cursor: 'pointer',
// };

// export default Overlay;