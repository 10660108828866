// AlertMessage.js
import React from 'react';

const AlertMessage = ({ message, type = 'primary' }) => {
    return (
        <div className={`alert alert-${type} alert-dismissible`} role="alert">
            {message}
            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>
    );
};

export default AlertMessage;
